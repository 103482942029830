import * as React from 'react'
const styles = require('scss/titleWithText.module.scss')
export interface BlurProps {
  title?: string
  text?: string
  titleNode?: React.ReactNode
  textNode?: React.ReactNode
}

const TitleWithText = (props: BlurProps) => {
  let title
  if (props.title) {
    title = (
      <div className={styles.title}>{props.title}</div>
    )
  }
  if (props.titleNode) {
    title = (
      <div className={styles.title}>{props.titleNode}</div>
    )
  }
  return (
    <div className={styles.container}>
      {title}

      {props.text ? (
        <div className={styles.text}>{props.text}</div>
      ) : (
        <div className={styles.text}>{props.textNode}</div>
      )}
    </div>
  )
}

export default TitleWithText
