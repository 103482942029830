import * as React from 'react'
import { SP, PC } from '../components/utils/media-query'
import HeadingImage from '../components/atoms/HeadingImage'
import TitleWithText from '../components/atoms/TitleWithText'
import Footer from '../components/organisms/Footer'
import { Link } from 'gatsby'
import ContactForm from '../components/molecules/ContactForm'
import HeadingIllust from '../components/atoms/HeadingIlust'
import Header from '../components/organisms/Header'

const styles = require('scss/contactDone.module.scss')
const ContactDone = () => {
  const spImage = require('images/sp/recruit_0.png')
  const pcImage = require('images/pc/recruit_0.png')
  const title = 'お問い合わせ'
  const pageName = 'Contact'

  const titleNode = <div>送信が完了しました。</div>
  const textNode = (
    <div>
      通常2営業日以内に返信致します。
      万が一返信がない場合やお急ぎの場合、kenta@nichicoma.co.jp
      までお問い合わせ下さい。
      <br />
      このたびはご連絡ありがとうございました。
    </div>
  )

  return (
    <section className={styles.wrapper}>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            image={spImage}
            title={title}
            pageName={pageName}
          />
        </SP>
        <PC>
          <HeadingIllust
            image={pcImage}
            title={title}
            pageName={pageName}
          />
        </PC>
      </div>
      <div>
        <TitleWithText
          titleNode={titleNode}
          textNode={textNode}
        />
      </div>
      <div className={styles.link}>
        <Link to="/">トップページに戻る</Link>
      </div>
      <Footer />
    </section>
  )
}
export default ContactDone
